import { FilterOperators } from '@stores/interfaces/Filter';
import { Load, Create } from '@stores/interfaces/Entity';
import SortDirections from '@constants/sort';

export interface ReportInterface {
  id: number;
  title: string;
  name: string;
  createdAt: number;
  generatedAt?: number;
  userId?: number;
  status: string;
  fileUrl?: string;
}

export class Report {
  public id: number;
  public title: string;
  public name: string;
  public createdAt: number;
  public generatedAt?: number;
  public userId?: number;
  public status: string;
  public fileUrl?: string;

  constructor(value: ReportInterface) {
    this.id = value.id;
    this.title = value.title;
    this.name = value.name;
    this.createdAt = value.createdAt;
    this.generatedAt = value.generatedAt;
    this.userId = value.userId;
    this.status = value.status;
    this.fileUrl = value.fileUrl;
  }
}

export type CreateAccountReport = Create<CreateAccountReportInterface, Promise<Report | null>>;
export type CreateCounterReport = Create<CreateCounterReportInterface, Promise<Report | null>>;
export type CreateOperatorReport = Create<CreateOperatorReportInterface, Promise<Report | null>>;
export type CreateIssueReport = Create<CreateIssueReportInterface, Promise<Report | null>>;
export type CreateInterruptReport = Create<CreateInterruptReportInterface, Promise<Report | null>>;
export type CreateInterruptReportSender = Create<
  CreateInterruptReportSenderInterface,
  Promise<Report | null>
>;

export interface CreateOperatorReportConfig {
  by: string;
  start: number;
  end: number;
}

export interface CreateIssueReportConfig {
  row: string;
  column?: string;
  start: number;
  end: number;
  sortby?: string;
  sortdir?: string;
  limit?: number;
}

export interface CreateCounterReportConfig {
  start: number;
  end: number;
  by: string;
  type: string;
}

export interface CreateOperatorReportInterface {
  title: string;
  config: CreateOperatorReportConfig;
  authId?: string;
}

export interface CreateCounterReportInterface {
  title: string;
  config: CreateCounterReportConfig;
  authId?: string;
}

export interface CreateAccountReportInterface {
  title: string;
  filter: CreateAccountReportConfig;
}

export enum ReportKeys {
  ID = 'id',
  NAME = 'name',
  USER_ID = 'userOd',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  DISTRICT_ID = 'districtId',
  STREET_ID = 'streetId',
  HOUSE_ID = 'houseId',
  FLAT_ID = 'flatId',
  CONTROL_ID = 'controlId',
  PROVIDER_ID = 'providerId',
  TRACKER_ID = 'trackerId',
  ORDERTYPE = 'ordertype',
  TYPE = 'orderType',
  SERVICE_ID = 'serviceId',
  PARENT_SERVICE_ID = 'parentServiceId',
  SENDER_ID = 'senderId',
  TELEPHONE_MESSAGE = 'telephoneMessage',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  REASON_ID = 'reasonId',
  TYPE_ID = 'typeId',
  START = 'start',
  END = 'end',
  CATEGORY_ID = 'categoryId',
  REPORT_TYPE = 'reportType',
  TIMESTAMP = 'timestamp',
}

export interface CreateAccountReportConfig {
  [ReportKeys.TIMESTAMP]: { $gte: number; $lte: number };
  [ReportKeys.HOUSE_ID]?: FilterOperators<number>;
  [ReportKeys.STREET_ID]?: FilterOperators<number>;
  [ReportKeys.CONTROL_ID]?: FilterOperators<number>;
  [ReportKeys.CATEGORY_ID]?: FilterOperators<number>;
  [ReportKeys.DISTRICT_ID]?: FilterOperators<number>;
  [ReportKeys.REPORT_TYPE]?: FilterOperators<number>;
}

export interface CreateInterruptReportInterface {
  title: string;
  userId?: string;
  filter?: {
    [ReportKeys.SENDER_ID]?: FilterOperators<number>;
    [ReportKeys.TELEPHONE_MESSAGE]?: FilterOperators<string>;
    [ReportKeys.START_DATE]?: FilterOperators<number>;
    [ReportKeys.END_DATE]?: FilterOperators<number>;
    [ReportKeys.REASON_ID]?: FilterOperators<number>;
    [ReportKeys.TYPE_ID]?: FilterOperators<number>;
    [ReportKeys.STREET_ID]?: FilterOperators<number>;
    [ReportKeys.HOUSE_ID]?: FilterOperators<number>;
    [ReportKeys.FLAT_ID]?: FilterOperators<number>;
  };
}

export interface CreateInterruptReportSenderInterface {
  title: string;
  userId?: string;
  config: {
    [ReportKeys.START]: number;
    [ReportKeys.END]: number;
  };
  filter?: {
    [ReportKeys.SENDER_ID]?: FilterOperators<number>;
    [ReportKeys.TELEPHONE_MESSAGE]?: FilterOperators<string>;
    [ReportKeys.REASON_ID]?: FilterOperators<number>;
    [ReportKeys.TYPE_ID]?: FilterOperators<number>;
    [ReportKeys.STREET_ID]?: FilterOperators<number>;
    [ReportKeys.HOUSE_ID]?: FilterOperators<number>;
    [ReportKeys.FLAT_ID]?: FilterOperators<number>;
    [ReportKeys.CONTROL_ID]?: FilterOperators<number>;
  };
}

export interface CreateIssueReportInterface {
  title: string;
  config: CreateIssueReportConfig;
  userId?: string;
  filter?: {
    [ReportKeys.DISTRICT_ID]?: FilterOperators<number>;
    [ReportKeys.STREET_ID]?: FilterOperators<number>;
    [ReportKeys.CONTROL_ID]?: FilterOperators<number>;
    [ReportKeys.PROVIDER_ID]?: FilterOperators<number>;
    [ReportKeys.TRACKER_ID]?: FilterOperators<number>;
    [ReportKeys.ORDERTYPE]?: FilterOperators<string>;
    [ReportKeys.TYPE]?: FilterOperators<string>;
    [ReportKeys.SERVICE_ID]?: FilterOperators<number>;
    [ReportKeys.PARENT_SERVICE_ID]?: FilterOperators<number>;
  };
}

export interface ReportFilterProps {
  [ReportKeys.ID]?: FilterOperators<number>;
  [ReportKeys.NAME]?: FilterOperators<string>;
  [ReportKeys.USER_ID]?: FilterOperators<number>;
  [ReportKeys.STATUS]?: FilterOperators<string>;
}

export interface ReportSortProps {
  field: ReportKeys;
  desc: SortDirections;
}

export type LoadReport = Load<
  { filter?: ReportFilterProps; sort?: ReportSortProps[]; limit?: number; offset?: number },
  Report[]
>;
