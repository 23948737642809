import { FilterOperators } from '@stores/interfaces/Filter';
import { Load } from '@stores/interfaces/Entity';

export interface ClassifierCategoryInterface {
  id: number;
  name?: string | null;
  type?: string | null;
  trackerId?: number | null;
  trackerName?: string | null;
}

export class ClassifierCategory {
  public id: number;
  public name?: string | null;
  public type?: string | null;
  public trackerId?: number | null;
  public trackerName?: string | null;

  public constructor(value: ClassifierCategoryInterface) {
    this.id = value.id;
    this.name = value.name;
    this.type = value.type;
    this.trackerId = value.trackerId;
    this.trackerName = value.trackerName;
  }
}

export enum ClassifierCategoryKeys {
  TRACKER_ID = 'trackerId',
  ORDERTYPE = 'ordertype',
}

export interface ClassifierCategoryFilterProps {
  [ClassifierCategoryKeys.TRACKER_ID]?: FilterOperators<number>;
  [ClassifierCategoryKeys.ORDERTYPE]?: FilterOperators<string>;
}

export type ClassifierCategoryLoad = Load<{
  filter?: ClassifierCategoryFilterProps;
  limit?: number;
  offset?: number;
}>;
